import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 10`}</h1>
    <p>{`We’re double digits now! Week 10 is here. This week covers powerful ambient by ju cha, delightful collection of tracks by De Lichting, Versalife combines cutting-edge electro with techno, and hard-hitting beats by VTSS and Bjarki.`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3109532402/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://angoisse1.bandcamp.com/album/chasms">Chasms by ju ca</a>
    </iframe>
    <h1>{`ju ca - Chasms`}</h1>
    <p>{`Feel more powerful with this ambient minimalism by ju cha.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://angoisse1.bandcamp.com/album/chasms"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=3209832812/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://delichting.bandcamp.com/album/tape-01">
    Tape 01 by De Lichting
  </a>
    </iframe>
    <h1>{`De Lichting - Tape 01`}</h1>
    <p>{`Delightful collection of tracks by De Lichting.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://delichting.bandcamp.com/album/tape-01"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180px" height="180px" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/755511049&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
    <h1>{`Versalife - Neogenesis`}</h1>
    <p>{`Versalife aka Conforce is back with four cutting-edge electro meets techno tracks.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://clone.nl/item58775.html"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe style={{
      "border": "0",
      "width": "180px",
      "height": "180px"
    }} src="https://bandcamp.com/EmbeddedPlayer/album=4123340500/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/" seamless>
  <a href="http://hellcatindustries.bandcamp.com/album/chanting-from-a-tiny-book">
    Chanting From a Tiny Book by VTSS / BJARKI
  </a>
    </iframe>
    <h1>{`VTSS / Bjarki - Chanting From a Tiny Book`}</h1>
    <p>{`Hard-hitting techno by VTSS and Bjarki.`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://hellcatindustries.bandcamp.com/album/chanting-from-a-tiny-book-ep"
      }}>{`Bandcamp`}</a><div style={{
        marginBottom: "80px"
      }} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      